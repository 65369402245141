// Libraries

import React from "react"
import { Link } from "gatsby"
import { Power3, TweenLite } from "gsap/TweenMax"

// Components

import SEO from "../components/global/SEO"

// Styles

import styled from "styled-components"

/*-----------------------------
Styles
-----------------------------*/

const TestPageWrapper = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
`

const TestPageBlock = styled.div`
  height: 100vh;
  background: ${props => props.theme.color.white};
  margin: 0 auto;
  opacity: 0.5;
`

/*-----------------------------
Component
-----------------------------*/

class TestPage extends React.Component {
  constructor(props) {
    super(props)
    // State
    this.state = {}
    // Refs
    this.pageWrapper = React.createRef()
    this.pageBlock = React.createRef()
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    if (this.props.animationStatus !== prevProps.animationStatus) {
      // Perform different animations based on the status of the current page transition.
      // Only animation for entering and exiting. The entered status should only trigger our animation on initial mount / first ever page load.
      switch (this.props.animationStatus) {
        case "entering":
          this.animateEntrance()
          break
        case "exiting":
          this.animateExit()
          break
        default:
      }
    }
  }

  /*-----------------------------
  Animations
  -----------------------------*/

  // Initial Load

  animateInitialLoad() {}

  // Entering From A Different Route

  animateEntrance() {
    // Perform the entrance transition.
    const pageWrapperNode = this.pageWrapper.current
    TweenLite.fromTo(
      pageWrapperNode,
      0.1,
      { opacity: 0 },
      {
        ease: Power3.easeOut,
        opacity: 1,
        delay: 1,
      }
    )
    TweenLite.fromTo(
      this.pageBlock.current,
      2.5,
      { width: "10%" },
      {
        ease: Power3.easeOut,
        width: "50%",
        delay: 1,
      }
    )
  }

  // Exiting To Another Route

  animateExit() {
    const pageWrapperNode = this.pageWrapper.current
    TweenLite.to(pageWrapperNode, 0.1, {
      ease: Power3.easeOut,
      opacity: 0,
      delay: 2.4,
    })
    TweenLite.to(this.pageBlock.current, 2, {
      width: "0%",
      ease: Power3.easeOut,
    })
  }

  /*-----------------------------
  Render
  -----------------------------*/

  render() {
    return (
      <TestPageWrapper ref={this.pageWrapper}>
        <SEO title="Home" />
        <Link
          to="/"
          style={{
            color: `#ffffff`,
            textDecoration: `none`,
            position: `absolute`,
            zIndex: `10`,
          }}
        >
          Home
        </Link>
        <TestPageBlock ref={this.pageBlock}></TestPageBlock>
      </TestPageWrapper>
    )
  }
}

export default TestPage
